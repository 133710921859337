import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import HeroComponent from "../components/HeroComponent";
import LipsGlance from "../components/glance.js";
import imgVideo from "../assets/image/jpeg/prp-main.jpg";
import Testimonial from "../sections/index/indexreviews.js";
import Saveface from '../components/save.js';
import Trend from '../components/trends.js';
import Vid from "../sections/prp/prpvideo.js"
import Content from "../sections/hair/haircontent.js";
import Package from "../sections/hair/hairpackage.js";
import Faq from "../sections/prp/prpfaq.js";
import Works from "../sections/prp/prpwork.js";
import Gallery from "../sections/hair/hairgallery.js";
import Head from "../components/head";
import Clinic from '../components/NewFind.js';  
import Award from "../components/NewToxane.js";
import { JsonLd } from '../components/JsonLd';
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"


const PrpPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
  <Head title="PRP Hair Restoration London" image="/images/dermamina-home.jpg"  description="Restore and strengthen hair with PRP hair treatment. Harness your body's natural healing for fuller, healthier hair. Discover the benefits now!" keywords="PRP, PRP hair treatment, Hair loss therapy, Platelet-rich plasma for hair, Hair regrowth treatment, PRP scalp treatment, Non-surgical hair restoration, Thinning hair solution, PRP injections for hair, Hair rejuvenation, Natural hair growth"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
'@type': 'ContactPoint',
telephone: '020 7099 6650',
contactType: 'Customer service',
},
address:{
'@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
{'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},


{'@type': 'ListItem', 
position:2,
name:'PRP Hair Restoration',
item:'https://www.dermamina.com/hair-restoration-london',
},

]
}}
</JsonLd>

<JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is PRP hair restoration?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "PRP hair restoration is a treatment that uses platelet-rich plasma to stimulate hair growth."
            }
          },
          {
            "@type": "Question",
            "name": "Does PRP Hair treatment hurt?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The treatment is minimally invasive and generally painless. The needles used for injecting the scalp are among the thinnest, making the procedure virtually painless."
            }
          },
          {
            "@type": "Question",
            "name": "How many PRP sessions are required?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Course of six sessions is needed; first three sessions are one month apart, followed by further 3 sessions once every three months thereafter. Maintenance treatments once or twice a year if needed. We will work together with you to create a treatment plan tailored to you, in order to achieve optimal results."
            }
          },
          {
            "@type": "Question",
            "name": "How long does PRP procedure take?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The PRP hair restoration procedure usually takes around 20 minutes."
            }
          }
        ]
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "600"
      }}
    </JsonLd>



    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "PRP Hair Restoration",
        "provider": {
          "@type": "Organization",
          "name": "Dermamina",
          "url": "https://www.dermamina.com"
        },
        "areaServed": {
          "@type": "Place",
          "name": "London"
        },
        "description": "PRP hair restoration is a treatment that uses platelet-rich plasma to stimulate hair growth.",
        "offers": {
          "@type": "Offer",
          "url": "https://www.dermamina.com/hair-restoration-london",
          "priceCurrency": "GBP",
          "price": "280",
          "eligibleRegion": {
            "@type": "Place",
            "name": "London"
          }
        }
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "PRP - Feel great with noticeable fuller & thicker hair",
        "description": "Video explaining what is PRP?",
        "thumbnailUrl": "https://www.dermamina.com/static/prp-main-f157907178478fe735d01b678610580f.jpg",
        "uploadDate": "2024-07-30T17:00:00+01:00",
        "embedUrl": "https://youtu.be/v4s4HQBiIos?si=8_m9063xsvuMZNqI",
        "contentUrl": "https://www.youtube.com/watch?v=v4s4HQBiIos"
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "ImageObject",
            "position": 1,
            "name": "Before PRP Hair Restoration",
            "contentUrl": "https://www.dermamina.com/static/p11-8d5285e3b18306fba02ad23c02d6eb90.jpg",
            "description": "Image showing hair condition before and after PRP hair restoration treatment."
          },
          {
            "@type": "ImageObject",
            "position": 2,
            "name": "After PRP Hair Restoration",
            "contentUrl": "https://www.dermamina.com/static/prp156-a7a882a25d5b8edaa26bb68b5fd74140.jpg",
            "description": "Image showing hair condition before and after PRP hair restoration treatment."
          }
        ]
      }}
    </JsonLd>


<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">PRP</span> <span style="color: #00aec7;">Hair Treatment</span>'
    secondText="Feel great with noticeable fuller & thicker hair"
    videoUrl="https://www.youtube.com/watch?v=v4s4HQBiIos" // Specify your video URL here
    videoAlt="PRP Video"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
<LipsGlance
   best="3-6 Sessions"
  bestalt="best PRP therapy"
  resultstext="Long Lasting"
  resultsalt="PRP therapy results"
  costdata="£280"
  costalt="PRP Cost"
  timedata="20 Minutes"
  timealt="PRP Duration Time"
  workdata="Immediately"
  workalt="PRP downtime work"
  paindata="Minimal / Moderate"
  painalt="PRP Pain"
  />
 </Col>
 
  </Row>
  </Container>
  </Section>
  <Gallery />
  <Content
    setIsModalOpen={setIsModalOpen}
  />
  <Testimonial />
<Award />
  <Package />
<Works />
<Faq
  setIsModalOpen={setIsModalOpen}
/>
<Vid />   
<Clinic />   
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default PrpPage;

